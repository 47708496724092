export const headerLogo = require("../assets/headerLogo.jpg");
export const background = require("../assets/backgroundImage.png");
export const backgroundLayer = require("../assets/backgroundLayer.png");
export const linkView = require("../assets/linkView.png");
export const group1 = require("../assets/group1.png");
export const group2 = require("../assets/group2.png");
export const group3 = require("../assets/group3.png");
export const group4 = require("../assets/group4.png");
export const view = require("../assets/view.png");
export const twitter = require("../assets/twitter.png");
export const faceBook = require("../assets/faceBook.png");
export const insta = require("../assets/insta.png");
export const linkedIn = require("../assets/linkedIn.png");
export const featureImg1 = require("../assets/featureImg1.png");
export const featureGroup1 = require("../assets/featureGroup1.png");
export const featureImg2 = require("../assets/featureImg2.png");
export const featureGroup2 = require("../assets/featureGroup2.png");
export const featureImg3 = require("../assets/featureImg3.png");
export const featureGroup3 = require("../assets/featureGroup3.png");
export const titleImage = require("../assets/titleImage.png");
export const rightArrow = require("../assets/rightArrow.png");
