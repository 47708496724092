import React from "react";

// Customizable Area Start
import { Box, Typography, TextField, Grid, InputAdornment, TextareaAutosize, OutlinedInput, FormControl, Button, styled } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import * as Yup from "yup";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { arrow_Left, drop_Down } from "./assets";
// Customizable Area End

import Cfchatgptintegration9Controller, {
  Props,
  configJSON,
} from "./Cfchatgptintegration9Controller";

export default class Cfchatgptintegration9 extends Cfchatgptintegration9Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  jobDescValidationSchema = Yup.object({
    jobTitle: Yup.string().required("Required field"),
    DepartmentTeam: Yup.string().required("Required field"),
    jobLevel: Yup.string().required("Required field"),
    jdStyle: Yup.string().required("Required field"),
    jobSummary: Yup.string().required("Required field"),  
    keySkills: Yup.string().required("Required field"),
    keyWords: Yup.string().required("Required field")
  });
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}>
        <MainWrapper>
          <Formik
            data-test-id="formik"
            initialValues={{
              jobTitle: '',
              DepartmentTeam: '',
              jobLevel: '',
              jdStyle: '',
              jobSummary: '',
              keySkills: '',
              keyWords: ''
            }}
            validationSchema={this.jobDescValidationSchema}
            onSubmit={(values: any) => {
              this.jobDescriptionCall(values)
            }}
          >
            {({
              handleSubmit,
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Grid container className="gridContainer">
                  <Grid item xs={12}>
                    <Box className="container">
                      <Box className="heading">
                        <Box data-test-id="Dashboard" onClick={() => this.navigateToDashboard()} style={{ width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <img src={arrow_Left.default} />
                        </Box>
                        <Typography className="head">Job Description generator</Typography>
                      </Box>
                      <Box className="formMainContainer">
                        <Box className="formcontainer">
                          <Typography className="titleHeading">Job Context</Typography>
                          <Box style={{ border: "1px solid #E2E8F0" }}></Box>
                          <Box className="nameContainer">
                            <Box className="nameInput"> 
                              <Box className="labeltexted">
                                <Typography className="labeltext"> 
                                  Job Title *
                                </Typography>
                                <ErrorMessage name="jobTitle" component="div" className="error" />
                              </Box>
                              <TextField
                                className="nameInputText"
                                placeholder="Specify your title"
                                fullWidth
                                name="jobTitle"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.jobTitle}
                              />
                            </Box>
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Department/Team *
                                </Typography>
                                <ErrorMessage name="DepartmentTeam" component="div" className="error" />
                              </Box>
                              <TextField
                                className="nameInputText"
                                placeholder="Specify your department/team"
                                fullWidth
                                name="DepartmentTeam"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DepartmentTeam}
                              />
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Job Level *
                                </Typography>
                                <ErrorMessage name="jobLevel" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown" data-test-id="setJobLevel" onClick={this.setJobLevel}                      >
                                <ClickAwayListener
                                  mouseEvent="onMouseDown"
                                  touchEvent="onTouchStart"
                                  onClickAway={this.setClickJobLevel}>
                                  <>
                                    <FormControl variant="outlined" className="nameInputText">
                                      <OutlinedInput
                                        placeholder="Specify your title"
                                        name="jobLevel"
                                        value={values.jobLevel}
                                        endAdornment={<InputAdornment position="end">
                                          <img src={drop_Down.default} />
                                        </InputAdornment>}
                                      />
                                    </FormControl>
                                    {this.state.jobLevelDropDown && (
                                      <Box className="menu">
                                        {this.state.jobLevel?.length >0 && this.state.jobLevel?.map((data: any, index: number) => 
                                        <Typography className="menuItem" data-test-id={`menuItem1${index}`}
                                          onClick={() => {
                                            setFieldValue("jobLevel", data.name)
                                          }}>{data.name}</Typography>)}
                                      </Box>
                                    )}</>
                                </ClickAwayListener>
                              </Box>
                            </Box>
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  JD style *
                                </Typography>
                                <ErrorMessage name="jdStyle" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown" data-test-id="setJdStyle" onClick={this.setJdStyle}>
                                <ClickAwayListener
                                  mouseEvent="onMouseDown"
                                  touchEvent="onTouchStart"
                                  onClickAway={this.setClickJdStyle}>
                                  <>
                                    <FormControl variant="outlined" className="nameInputText">
                                      <OutlinedInput
                                        placeholder="Select your JD className"
                                        name="jdStyle"
                                        value={values.jdStyle}
                                        endAdornment={<InputAdornment position="end">
                                          <img src={drop_Down.default} />
                                        </InputAdornment>}
                                      />
                                    </FormControl>
                                    {this.state.jdStyleDropDown && (
                                      <Box className="menu">
                                        {this.state.jdstyle?.length > 0 && this.state.jdstyle?.map((data: any, index: number) => <Typography className="menuItem" 
                                        data-test-id={`menuItem2${index}`}
                                          onClick={() => setFieldValue("jdStyle", data.name)}>{data.name}</Typography>)}
                                      </Box>
                                    )}
                                  </>
                                </ClickAwayListener>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Box className="desInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Job Summary *
                                </Typography>
                                <ErrorMessage name="jobSummary" component="div" className="error" />
                              </Box>
                              <TextareaAutosize className="descInputText" minRows={5} placeholder="Write about your job..."
                                onChange={handleChange}
                                name="jobSummary"
                                onBlur={handleBlur}
                                value={values.jobSummary} />
                              <Typography className="limit">0/1000</Typography>
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Key Skills *
                                </Typography>
                                <ErrorMessage name="keySkills" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown">
                                <TextField
                                className="nameInputText"
                                placeholder="Select your key skills"
                                fullWidth
                                name="keySkills"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.keySkills}
                              />
                              </Box>
                            </Box>
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Key Words *
                                </Typography>
                                <ErrorMessage name="keyWords" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown">
                                <TextField
                                className="nameInputText"
                                placeholder="Select your key words"
                                fullWidth
                                name="keyWords"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.keyWords}
                              />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Button className="submit" type="submit">Submit</Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </MainWrapper>
      </DashboardHeader>


      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const MainWrapper = styled(Box)({
  width: "100%",
  height: "calc(100vh - 180px)",
  paddingBottom: "30px",
  "& .arabicDirection": {
    direction: "rtl",
  },
  "& .container": {
    width: "100vw",
  },
  "& .heading": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginLeft: "35px",
    marginTop: "30px",
    marginBottom: "30px",
  },
  "& .head": {
    fontSize: "25px",
    fontWeight: 600,
    fontFamily: "Urbanist",
  },
  "& .formMainContainer": {
    paddingLeft: "7rem",
    paddingBottom: "50px",

    "@media(max-width:1050px)": {
      paddingLeft: "3rem",
    },
    "@media(max-width:900px)": {
      paddingLeft: "7rem",
    },
    "@media(max-width:675px)": {
      paddingLeft: "4rem",
    },
    "@media(max-width:570px)": {
      paddingLeft: "1.5rem",
    },
    "@media(max-width:390px)": {
      paddingLeft: "0.5rem",
    },
 
  },
  "& .formcontainer": {
    width: "830px",
    paddingBottom: "50px",
    boxShadow: "0px 14px 144px 0px #0000001A",

    "@media(max-width:1000px)": {
      width: "fit-content",
    },
    "@media(min-width:1000px)": {
      width: "750px",
    },
    "@media(min-width:1200px)": {
      width: "830px",
    },
  },
  "& .titleHeading": {
    padding: "1.5rem",
    paddingLeft: "40px",
    color: "#044352",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Urbanist",
  },

  "& .nameInput": {
    width: "350px",
  },
  "& .labeltexted": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .labeltext": {
    color: "#344054",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Urbanist",
  },
  "& .error": {
    color: "#F87171",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Urbanist",
  },
  "& .mainDropDown": {
    position: "relative" as "relative",
  },
  "& .menu": {
    width: "350px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px 0px #00000026",
    position: "absolute" as "absolute",
    borderRadius: "8px",
    zIndex: 10
  },
  "& .menuItem":{
    padding:"12px 16px 12px 16px",
    color:"#475569",
    "&::placeholder": {
    color: "#94A3B8",
}
  },
  "& .nameInputText": { "&::placeholder": {
    color: "#94A3B8",
    },
    width: "100%",
    borderColor:"#CBD5E1",
    borderRadius:"8px",color:"#475569", 
    fontWeight: 400, 
    fontSize: "16px" },
  "& .descInputText": {  
    "&::placeholder": {
    color: "rgba(0, 0, 0, 0.65)",
    }, 
    width: "100%",
    border:"1px solid #CBD5E1",
    borderRadius:"8px",
    color:"#475569", 
    fontWeight: 400, 
    fontSize: "16px", 
    padding:"8px" },
  "& .desInput": {
    width: "740px",
  },
  "& .limit": {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Urbanist",
  },
  "& .submit": {
    width: "740px",
    color: "#FFFFFF",
    backgroundColor: "#044352",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Urbanist",
    zIndex: 1
  },
  "& .nameContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "3rem",
    marginTop: "2rem",
    padding: "0rem !important",
    "@media(max-width:1000px)": {
      flexWrap: "wrap",
      width: "30rem",
      padding: "0 1rem",
    },
    "@media(max-width:500px)": {
      width: "23rem",
      padding: "1rem",
    },
    "@media(min-width:1000px)": {
      padding: "0 2rem"
    },
    "@media(min-width:1200px)": {
      width: "100%",
      flexWrap: "no-wrap",
    },
  },
  "& .emptyMainText": {
    fontFamily: "Urbanist",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#000104"
  },
  "& .emptyNextText": {
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#8A96A8",
    width: "50%",
    textAlign: "center"
  },
  "& .imageBox": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  "& .gridContainer": {
    height: "100%",
  }
});
// Customizable Area End
