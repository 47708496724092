Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getApiMethodType = "GET";
exports.getStartedApiEndPoint = "bx_block_landingpage2/get_started";
exports.getFeaturesApiEndPoint = "bx_block_landingpage2/features";
exports.getTestimonialsApiEndPoint = "bx_block_landingpage2/testimonials";
exports.getHeaderFooterApiEndPoint = "bx_block_landingpage2/header_footer";
exports.getTermsAndConditionsApiEndPoint = "bx_block_terms_and_conditions/term_and_condition";
exports.PrivacyPolicyApiEndPoint = "bx_block_terms_and_conditions/privacy_policy";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End