Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiFormDataContentType = "multipart/form-data";
exports.validationApiMethodType = "POST";
exports.apiMethodPut = "PUT";
exports.apiMethodGet = "GET";
exports.urlaccounts = "/account_block/accounts";
exports.sms_confirmations = "/account_block/accounts/sms_confirmations";
exports.need_permission = "/need_permission";
exports.update_info = "/bx_block_content_management/profile";
exports.front_photo_attach =
  "/bx_block_content_management/vehical_information/front_photo_attach";
exports.rear_photo_attach =
  "/bx_block_content_management/vehical_information/rear_photo_attach";
exports.front_insurance_card =
  "/bx_block_termsandconditions/insurance_information/attach_insurance_card";
exports.addInsurance_Info = "/bx_block_termsandconditions/insurance_information";
exports.back_insurance_card = "/back_insurance_card/2";
exports.insurance_information = "/insurance_information";
exports.upload_profile_photo =
  "/bx_block_content_management/profile/upload_profile_photo";
exports.token = "";
exports.Welcometext = "Welcome to HR tech! Your account"
exports.welcontext1 = "is currently under review. �"
exports.signUpUrl = "/account_block/accounts";
exports.policyConsent ="I have read and agree to Privacy Policy";
exports.termsAndConditionEndPoint ="/bx_block_terms_and_conditions/term_and_condition"; 
exports.privacypolicyEndPoint ="/bx_block_terms_and_conditions/privacy_policy";
exports.termsAndConditionsMsg ="You must agree to the Terms and Conditions, Privacy policy to register";
exports.termsErrMsg ="You must agree to the Terms and Conditions";
exports.policyErrMsg = "You must agree to the Privacy policy";
exports.reviewUser="Thank you for signing up with your organization email. Our administrators are reviewing your request to ensure that you have the right access to the resources you need.";
exports.shortMessage ="Fusce volutpat lectus et nisl consectetur finibus. In vitae scelerisque augue, in varius eros.";
// Customizable Area End