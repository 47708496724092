export const headerLogo = require("../assets/headerLogo.jpg");
export const allUsersImg = require("../assets/allUsersImg.png");
export const activeAllUsersImg = require("../assets/activeAllUsersImg.png");
export const assessmentImg = require("../assets/assessmentImg.png");
export const activeAssessmentImg = require("../assets/activeAssessmentImg.png");
export const companyImg = require("../assets/companyImg.png");
export const activeCompanyImg = require("../assets/activeCompanyImg.png");
export const competenciesImg = require("../assets/competenciesImg.png");
export const activeCompetenciesImg = require("../assets/activeCompetenciesImg.png");
export const criticalImg = require("../assets/criticalImg.png");
export const activeCriticalImg = require("../assets/activeCriticalImg.png");
export const dashboardImg = require("../assets/dashboardImg.png");
export const jobImg = require("../assets/jobImg.png");
export const activeJobImg = require("../assets/activeJobImg.png");
export const logoutImg = require("../assets/logoutImg.png");
export const questionsImg = require("../assets/questionsImg.png");
export const activeQuestionsImg = require("../assets/activeQuestionsImg.png");
export const menuBgImg = require("../assets/menuBgImg.png");
export const activeDashboardImg = require("../assets/activeDashboardImg.png");
export const rightArrow = require("../assets/rightArrow.png");
export const notifications = require("../assets/notifications.png");
export const defaultUserImg = require("../assets/defaultUserImg.png");
export const emptyDashboardImg = require("../assets/emptyDashboardImg.png");
export const closeSidebarArrow = require("../assets/closeSidebarArrow.png");
export const viewProfileImg = require("../assets/viewProfileImg.png");
export const profileSettingImg = require("../assets/profileSettingImg.png");