import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import Swiper from 'swiper';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

interface GetStartedData {
  error?: string,
  id?: string,
  fast_button?: string,
  start_title?: string,
  start_tag_line?: string,
  get_started?: string,
  image_urls?: string[],
  border_text?: string[]
}

interface GetFeaturesData {
  error?: string,
  our_feature?: string,
  feature_title?: string,
  features?: {
    id: number,
    feature_sub_title: string,
    feature_description: string
  }[]
}

interface GetTesimonialsData {
  error?: string,
  testimonial?: string,
  testimonial_title?: string,
  testimonials?: {
    id: number,
    testimonial_desc: string,
    testimonial_full_name: string,
    testimonial_user_name: string,
    profile_photo_url: string
  }[]
}

interface HeaderFooterData {
  error?: string,
  header?: {
    language: string,
    header_features: string,
    header_testimonials: string,
    header_contact_us: string,
    sign_in_text: string,
  },
  footer?: {
    footer_headline: string,
    footer_title: string,
    footer_description: string,
    footer_company_info: string,
    footer_about_us: string,
    footer_features: string,
    footer_terms_conditions: string,
    footer_privacy_policy: string,
    twitter_link: string,
    facebook_link: string,
    instagram_link: string,
    linkedin_link: string,
  }
}
interface TermsAndCondition {
    error?: string, 
    description?: string;
    title?: string;
    language?: string;
    checkbox?: string;
}
interface PrivacyPolicy {
  error?: string, 
  description?: string;
  title?: string;
  language?: string;
  checkbox?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  languageDropdown: boolean;
  selectedLanguage: string;
  getStartedData: GetStartedData;
  getFeaturesData: GetFeaturesData;
  getTesimonialsData: GetTesimonialsData;
  headerFooterData: HeaderFooterData;
  errorMsg: string;
  openTerms: boolean;
  checkedTerms: boolean;
  openPrivacy: boolean;
  checkedPrivacy: boolean;
  openLearnMore: boolean;
  selectedFeatureIndex: number;
  termsAndConditionsData: TermsAndCondition;
  privacyPolicyData: PrivacyPolicy;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStartedApiCallId: string = "";
  getFeaturesApiCallId: string = "";
  getTestimonialsApiCallId: string = "";
  getHeaderFooterApiCallId: string = "";
  TermsAndConditionsApiCallId: string = "";
  PrivacyPolicyApiCallId: string = "";
  swiperContainerRef: RefObject<HTMLDivElement>;
  nextButtonRef: RefObject<HTMLDivElement>;
  prevButtonRef: RefObject<HTMLDivElement>;
  paginationRef: RefObject<HTMLDivElement>;
  swiperObj: Swiper;
  featuresRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
  testimonialsRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
  getStartedRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
  mainHeaderRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
  contactUsRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      languageDropdown: false,
      selectedLanguage: "English",
      getStartedData: {},
      getFeaturesData: {},
      getTesimonialsData: {},
      errorMsg: "",
      headerFooterData: {},
      openTerms: false,
      checkedTerms: false,
      openPrivacy: false,
      checkedPrivacy: false,
      openLearnMore: false,
      selectedFeatureIndex: 0,
      termsAndConditionsData: {},
      privacyPolicyData: {}
    };

    this.swiperContainerRef = React.createRef();
    this.nextButtonRef = React.createRef();
    this.prevButtonRef = React.createRef();
    this.paginationRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getStartedApiCallId) {
        this.handleGetStartedApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getFeaturesApiCallId) {
        this.handleFeaturesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getTestimonialsApiCallId) {
        this.handleTestimonialsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getHeaderFooterApiCallId) {
        this.handleHeaderFooterApiResponse(responseJson)
      }
      if (apiRequestCallId === this.TermsAndConditionsApiCallId) {
        this.termsAndConditionApiResponse(responseJson)
      }
      if (apiRequestCallId === this.PrivacyPolicyApiCallId) {
        this.privacyPolicyApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language }, () => {
      this.getApiCalls();
    });
  }

  async componentWillUnmount() {
    if (this.swiperObj) this.swiperObj.destroy();
  }

  handleMouseEnter = () => {
    if (this.swiperObj && this.swiperObj.autoplay) {
      this.swiperObj.autoplay.stop();
    }
  }

  handleMouseLeave = () => {
    if (this.swiperObj && this.swiperObj.autoplay) {
      this.swiperObj.autoplay.start();
    }
  }

  initilizeTestimonialsSwiper = () => {
    const swiperOptions = {
      slidesPerView: 3,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: this.paginationRef.current,
        clickable: true,
      },
      navigation: {
        nextEl: this.nextButtonRef.current,
        prevEl: this.prevButtonRef.current,
      },
      breakpoints: {
        360: {
          slidesPerView: 1
        },
        600: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      },
      mousewheel: true,
      keyboard: true,
      modules: [Navigation, Pagination, Autoplay]
    };

    if (this.swiperContainerRef.current) {
      this.swiperObj = new Swiper(this.swiperContainerRef.current, swiperOptions);
    }
  }

  scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  handleClickOpen = () => {
    this.getTermsAndConditionsApiCall();
    this.setState({ openTerms: true });
  };

  handleClose = () => {
    this.setState({ openTerms: false });
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkedTerms: event.target.checked });
  };

  handleClickOpenPrivacy = () => {
    this.getPrivacyPolicyApiCall();
    this.setState({ openPrivacy: true });
  };

  handleClosePrivacy = () => {
    this.setState({ openPrivacy: false });
  };

  handleCheckboxChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkedPrivacy: event.target.checked });
  };

  handleOpenLearnMore = (currentIndex: number) => {
    this.setState({ openLearnMore: true, selectedFeatureIndex: currentIndex })
  }

  handleCloseLearnMore = () => {
    this.setState({ openLearnMore: false })
  }

  navigateToTwitter = () => {
    window.open(this.state.headerFooterData?.footer?.twitter_link, '_blank')
  }
  
  navigateToFaceBook = () => {
    window.open(this.state.headerFooterData?.footer?.facebook_link, '_blank')
  }

  navigateToInsta = () => {
    window.open(this.state.headerFooterData?.footer?.instagram_link, '_blank')
  }

  navigateToLinkedIn = () => {
    window.open(this.state.headerFooterData?.footer?.linkedin_link, '_blank')
  }

  goToSignup =()=>{
    this.props.navigation.navigate("WelcomeScreen")
  }

  goToSignIn=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  getApiCalls = () => {
    this.getStartedApiCall();
    this.getFeaturesApiCall();
    this.getTestimonialsApiCall();
    this.getHeaderFooterApiCall();
  }

  handleGetStartedApiResponse = (responseJson: GetStartedData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ getStartedData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleFeaturesApiResponse = (responseJson: GetFeaturesData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ getFeaturesData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleTestimonialsApiResponse = (responseJson: GetTesimonialsData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ getTesimonialsData: responseJson }, () => setTimeout(() => this.initilizeTestimonialsSwiper(), 1000))
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }
  handleHeaderFooterApiResponse = (responseJson: HeaderFooterData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ headerFooterData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  termsAndConditionApiResponse = (responseJson: TermsAndCondition) => {
    if (responseJson && !responseJson.error) {
      this.setState({ termsAndConditionsData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  privacyPolicyApiResponse = (responseJson: PrivacyPolicy) => {
    if (responseJson && !responseJson.error) {
      this.setState({ privacyPolicyData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      languageDropdown: !prevState.languageDropdown,
    }));
  };

  languageChange = (language: string) => {
    setStorageData("language", language);
    location.reload();
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getStartedApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";

    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForGetStarted = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStartedApiCallId = getMsgForGetStarted.messageId;
    getMsgForGetStarted.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStartedApiEndPoint}?language=${lang}`
    );
    getMsgForGetStarted.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForGetStarted.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForGetStarted.id, getMsgForGetStarted);
  }

  getFeaturesApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";
    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForFeature = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeaturesApiCallId = getMsgForFeature.messageId;
    getMsgForFeature.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeaturesApiEndPoint}?language=${lang}`
    );
    getMsgForFeature.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForFeature.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForFeature.id, getMsgForFeature);
  }

  getTestimonialsApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";

    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForTestimonials = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTestimonialsApiCallId = getMsgForTestimonials.messageId;
    getMsgForTestimonials.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTestimonialsApiEndPoint}?language=${lang}`
    );
    getMsgForTestimonials.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForTestimonials.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForTestimonials.id, getMsgForTestimonials);
  }

  getHeaderFooterApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";

    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForHeaderFooter = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHeaderFooterApiCallId = getMsgForHeaderFooter.messageId;
    getMsgForHeaderFooter.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHeaderFooterApiEndPoint}?language=${lang}`
    );
    getMsgForHeaderFooter.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForHeaderFooter.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForHeaderFooter.id, getMsgForHeaderFooter);
  }

  getTermsAndConditionsApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";

    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForTerms = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.TermsAndConditionsApiCallId = getMsgForTerms.messageId;
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTermsAndConditionsApiEndPoint}?language=${lang}`
    );
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForTerms.id, getMsgForTerms);
  }

  getPrivacyPolicyApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";

    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForTerms = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PrivacyPolicyApiCallId = getMsgForTerms.messageId;
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PrivacyPolicyApiEndPoint}?language=${lang}`
    );
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForTerms.id, getMsgForTerms);
  }
  // Customizable Area End
}
