import { BlockComponent } from "./../../../framework/src/BlockComponent";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { WithTranslation } from "react-i18next";
import { ChangeEvent} from 'react';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { getStorageData} from "framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
interface UserDetails {
  full_name: string;
  email: string;
  password: string;
  ischeckboxChecked : boolean;
}

interface Attributes {
  id: number;
  description: string;
  title: string;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

interface ErrorResponse {
  password?: string[];
  full_name?: string[];
  email?: string[];
  errors?:{}
  term_condition? :string
}

interface TermsDetails {
  isTermsChecked: boolean;
}

interface PolicyDetails {
  isPrivacyPolicyChecked: boolean;
}

interface ResponseForTerms {
  errors: "",
  id: number;
  account_id: number | null;
  created_at: string;
  updated_at: string;
  description: string;
  title: string;
  language: string;
  checkbox: string;
}
// Customizable Area End

export interface Props extends WithTranslation {
  navigation: any;
  // Customizable Area Start
  id:string;
  identifiable: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedLanguageSignIn: string;
  existingEmail : any;
  policyMessage: string;
  privacyPolicyTerm: string;
  termsMessage : string;
  termsDetails: TermsDetails;
  policyDetails: PolicyDetails;
  showCheckboxError: boolean;
  terms:string;
  apiErrors: ErrorResponse;
  userDetails: UserDetails;
  isLoading: boolean;
  languageScreen: boolean;
  token: string;
  filesWeb: File[];
  files: any[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  policyOpen: boolean;
  termsOpen: boolean;
  full_name: string;
  email: string;
  password: string;
  isChecked: boolean;
  showPassword: boolean;
  selectedCurrency: string;
  buttonBackgroundColor: string;
  buttonColorchange: string;
  agreePrivacy: string;
  code: string;
  showVerification: boolean;
  errorMessage: string;
  redOtpbox: boolean;
  verifyEmail: boolean;
  userSignedUp:boolean;
  termsAndConditionData : ResponseForTerms ;
  privacyPolicyData : ResponseForTerms ;
  errorMsg : string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export default class WelcomeScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreatePostCallId: string = "";
  signUpApiCallId: string = ""
  getTermsAndCondition: string = "";
  getPrivacyPolicy: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      selectedLanguageSignIn: "",
      existingEmail:null,
      showCheckboxError: false,
      privacyPolicyTerm : "",
      termsMessage : "",
      policyMessage: "",
      termsDetails: {
        isTermsChecked: false,
      },
      policyDetails: {
        isPrivacyPolicyChecked: false,
      },
      apiErrors: {},
      userDetails: {
        full_name: '',
        email: '',
        password: '',
        ischeckboxChecked: false,
      },
      terms:"",
      isLoading: false,
      languageScreen: false,
      token: "",
      filesWeb: [],
      files: [],
      filesStatus: [],
      termsOpen: false,
      policyOpen: false,
      full_name: '',
      email: "",
      password: "",
      showPassword: false,
      selectedCurrency: "",
      isChecked: false,
      buttonBackgroundColor: "",
      buttonColorchange: "",
      agreePrivacy: "",
      code: "",
      redOtpbox: false,
      showVerification: false,
      errorMessage: "",
      verifyEmail: false,
      userSignedUp:false,
      termsAndConditionData: {
        errors:"",
        id: 2,
        account_id: null,
        created_at: "",
        updated_at: "",
        description: "",
        title: "",
        language: "",
        checkbox: "",
       },
      privacyPolicyData : {
        errors:"",
        id: 2,
        account_id: null,
        created_at: "",
        updated_at: "",
        description: "",
        title: "",
        language: "",
        checkbox: "",
      },
      errorMsg : "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.handlePolicyOpen = this.handlePolicyOpen.bind(this);
    this.handlePolicyClose = this.handlePolicyClose.bind(this);
    this.handleTermsOpen = this.handleTermsOpen.bind(this);
    this.handleTermsClose = this.handleTermsClose.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleButtonEmailVerify = this.handleButtonEmailVerify.bind(this);
    this.goTosignin = this.goTosignin.bind(this);
    this.signUpApiCallResponse =  this.signUpApiCallResponse.bind(this)
  }

  async componentDidMount() {
    super.componentDidMount();
    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguageSignIn: language }, () => {
      this.getApiCalls();
    });
  }
   


  async receive(from: string, message: Message) {
   

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorResponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.signUpApiCallId){
          this.signUpApiCallResponse(responseJson,errorResponse)
        }
        if (apiRequestCallId === this.getTermsAndCondition) {
          this.handleTermsAndConApiResponse(responseJson)
        }
        if (apiRequestCallId === this.getPrivacyPolicy) {
          this.handlePrivacyPolicyApiResponse(responseJson)
        }
      }
     
    }
   
  
        

  }

  handlePolicyOpen() {
    this.setState({ policyOpen: true });
  }

  handlePolicyClose() {
    this.setState({ policyOpen: false });
  }

  handleTermsOpen() {
    this.setState({ termsOpen: true });
  }

  handleTermsClose() {
    this.setState({ termsOpen: false });
  }

  handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, checked } = event?.target;
    this.setState((prevState) => {
      let updatedState = {};
      if (name === 'ischeckboxChecked') {
        updatedState = {
          userDetails: {
            ...prevState.userDetails,
            ischeckboxChecked: checked,
          },
          showCheckboxError: !checked,
          buttonBackgroundColor: checked ? '#801187' : '',
          buttonColorchange: checked ? '#ffffff' : '',
          agreePrivacy: checked ? '#801187' : '',
        };
      } else if (name === 'isTermsChecked') {
        updatedState = {
          termsDetails: {
            ...prevState.termsDetails,
            isTermsChecked: checked,
          },
        };
      } else if (name === 'isPrivacyPolicyChecked') {
        updatedState = {
          policyDetails: {
            ...prevState.policyDetails,
            isPrivacyPolicyChecked: checked,
          },
        };
      }
  
      return updatedState;
    });
  }
  

  clearSpecificErrors(fieldName: string): void {
    if (fieldName === 'email') {
      this.setState({
        existingEmail: null,
      });
    }
  }

  handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      userDetails: {
        ...prevState.userDetails,
        [name]: value,
      },
      apiErrors: {
        ...prevState.apiErrors,
        [name]: undefined,  
      }
      
    }));
    this.clearSpecificErrors(name);
  }

  handleClickShowPassword() {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  checkifPolicy =()=>{
    const { isPrivacyPolicyChecked } = this.state.policyDetails;
    if(!isPrivacyPolicyChecked){
      this.setState({ policyMessage: configJSON.policyErrMsg });
    }
    else{
      this.setState({ policyOpen: false });
    }
  }

  checkForTerms =()=>{
    const { isTermsChecked } = this.state.termsDetails;
    if(!isTermsChecked){
      this.setState({ termsMessage: configJSON.termsErrMsg });
    }
    else{
      this.setState({ termsOpen: false });
    }
  }

  handleButtonEmailVerify() {
    this.setState({
      verifyEmail: true
    });
  }

  goTosignin() {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  handleNavigateLanding=()=>{
    this.props.navigation.navigate("LandingPage");
  }

  SignUp=()=>{
      this.signUpApiCall()
  }

  getApiCalls = () => {
    this.getTermsApiCall();
    this.getPrivacyApiCall();
  }
  
  signUpApiCall = () => { 
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody: any = {
      "data": {
        "type": "email_account",
        "attributes":{
          "full_name":this.state.userDetails.full_name,
          "email": this.state.userDetails.email,
          "password":this.state.userDetails.password,
           "term_condition": this.state.userDetails.ischeckboxChecked
        }
      }
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  signUpApiCallResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({ apiErrors: responseJson?.errors,
        existingEmail: responseJson[0]?.errors?.email
       });
      if (responseJson?.meta?.token) {
        this.setState({ userSignedUp: true });
      } else {
        this.setState({ userSignedUp: false });
      }
      
    } else if (responseJson) {
      this.setState({
        apiErrors: responseJson.error.errors
      });
    }
  }

  getTermsApiCall = () => {
    const lang = this.state.selectedLanguageSignIn === "English" ? "english" : "arabic";
    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForTerms = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
     this.getTermsAndCondition = getMsgForTerms.messageId;
     getMsgForTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.termsAndConditionEndPoint}?language=${lang}`
    );
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForTerms.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGet
    );
    runEngine.sendMessage(getMsgForTerms.id, getMsgForTerms);
  }

  getPrivacyApiCall =() => {
    const lang = this.state.selectedLanguageSignIn === "English" ? "english" : "arabic";
    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForPrivacyPolicy = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicy = getMsgForPrivacyPolicy.messageId;
    getMsgForPrivacyPolicy.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.privacypolicyEndPoint}?language=${lang}`
    );
    getMsgForPrivacyPolicy.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForPrivacyPolicy.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGet
    );
    runEngine.sendMessage(getMsgForPrivacyPolicy.id, getMsgForPrivacyPolicy);
  }

  handlePrivacyPolicyApiResponse = (responseJson: ResponseForTerms) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ privacyPolicyData: responseJson })
    } else if (responseJson && responseJson.errors) {
      this.setState({
        errorMsg: responseJson.errors
      });
    }
  }

  handleTermsAndConApiResponse = (responseJson:ResponseForTerms) => {
   
    if (responseJson && !responseJson.errors) {
      this.setState({ termsAndConditionData: responseJson })
    }
     else if (responseJson && responseJson.errors) {
      this.setState({
        errorMsg: responseJson.errors
      });
    }
  }

  

}
// Customizable Area End
