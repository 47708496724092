import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Input,
    Card,
    CardContent,
    Chip,
    Divider,
    Select,
    InputBase,
    MenuItem,
    Button
} from "@mui/material";
import { backArrow, uploadImage, more } from "./assets";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// Customizable Area End

import CompanyDetailsController, {
    Props
} from "./CompanyDetailsController";

export default class CompanyDetails extends CompanyDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    companyCulture = () => {
        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    Company culture <span>*</span>
                </Typography>
                <textarea
                    data-test-id="company_culture"
                    className="cultureInputField"
                    placeholder="Company culture"
                />
                <Typography className="companyCultureLimit">0/1000</Typography>
            </Wrapper>
        )
    }
    industryType = () => {
        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    Industry type  <span>*</span>
                </Typography>
                <Select
                    data-test-id="industry_type"
                    className="dropDownStyle"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selctedIndustryType}
                    renderValue={
                        this.state.selctedIndustryType
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your industry type
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.industryType.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    companySize = () => {
        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    Company size  <span>*</span>
                </Typography>
                <Select
                    data-test-id="company_size"
                    className="dropDownStyle"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedCompanySize}
                    displayEmpty
                    renderValue={
                        this.state.selectedCompanySize
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your company size
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.companySize.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    companyStage = () => {
        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    Company Stage  <span>*</span>
                </Typography>
                <Select
                    data-test-id="company_stage"
                    className="dropDownStyle"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedCompanyStage}
                    displayEmpty
                    renderValue={
                        this.state.selectedCompanyStage
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your company stage
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.companyStage.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    jdStyle = () => {
        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    JD Style  <span>*</span>
                </Typography>
                <Select
                    data-test-id="jd_style"
                    className="dropDownStyle"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedJdStyle}
                    displayEmpty
                    renderValue={
                        this.state.selectedJdStyle
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your JD style
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.jdStyle.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    organizationKeywords = () => {
        const chipData = [
            { key: 0, label: 'Angular' },
            { key: 1, label: 'Manual Tester' },
            { key: 2, label: 'Polymer' },
            { key: 3, label: 'React' },
            { key: 4, label: 'Vue.js' },
        ];

        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    Organization keywords <span>*</span>
                </Typography>
                <Box className="organizationKeywords">
                    <Input
                        className="orgKeywordInput"
                        fullWidth
                        data-test-id="organization_keywords"
                        placeholder="Enter your keywords here..."
                        sx={{
                            '&:before': {
                                borderBottom: 'none',
                            },
                            '&:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                            '&:after': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                    <Box className="chipContainer">
                        {chipData.map((data) => {
                            return (
                                <Chip
                                    className="chip"
                                    key={data.key}
                                    label={data.label}
                                    onClick={() => { console.log("click") }}
                                    onDelete={() => { console.log("delete") }}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Wrapper>
        )

    }

    jdTemplates = () => {
        return (
            <Wrapper>
                <Typography className="cardInputLabel">
                    Upload JD templates  <span>*</span>
                </Typography>
                <Box className="jdMainBox">
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item xs={12} sm={2} md={1.5} className="jdInnerGrid">
                            <img
                                src={uploadImage.default}
                                alt="upload Image"
                            />
                        </Grid>
                        <Grid item xs={12} sm={9} md={9.5} sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            },
                            alignItems: {
                                xs: 'center',
                                sm: 'flex-start',
                            },
                        }}>
                            <Typography className="jdUploadText">Upload your JD document</Typography>
                            <Typography className="jdUploadSubText">You can upload word and pdf files up to 5 mb's</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1} className="deleteJdImg">
                            <img src={more.default}
                                alt="Delete document"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    submitButton = () => {
        return (
            <Wrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Button className="sumbmitBtn">
                            <Typography className="sumbmitText">Submit</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </Wrapper>
        )
    }

    companyFormHeader = () => {
        return (
            <Wrapper>
                <Box className="companyItemMainBox">
                    <Grid container className="companyCardContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="companyCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                Organisation details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.companyCulture()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.industryType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.companySize()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.companyStage()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.jdStyle()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.organizationKeywords()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.jdTemplates()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.submitButton()}
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Company details" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                                    <Typography className="CompanyTitle">Company Details</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className="compMainHeaderGrid">
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.companyFormHeader()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .companyCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .companyItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .companyCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .cardInputLabel": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        color: "#344054",
        marginBottom: "10px"
    },
    "& .companyCardContainer": {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .chipContainer": {
        borderTop: '1px solid #CBD5E1',
        padding: "12px 16px",
    },
    "& .chip": {
        border: '1px solid #CBD5E1',
        margin: "12px 12px 12px 0px",
        borderRadius: "8px",
    },
    "& .organizationKeywords": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px",
    },
    "& .orgKeywordInput": {
        border: 0, padding: "24px 12px 24px"
    },
    "& .jdMainBox": {
        display: "flex",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "20px 5px"
    },
    "& .jdInnerGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .deleteJdImg": {
        display: "flex",
        justifyContent: "center"
    },
    "& .jdUploadText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#0F172A"
    },
    "& .jdUploadSubText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B"

    },
    "& .cultureInputField": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .companyCultureLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Urbanist",
    },
    "& .dropDownStyle": {
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 400,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 8px',
        width: '100%'
    },
    "& .dropDownStyleplaceHolder": {
        color: "#DCDCDC", fontSize: 16
    } as React.CSSProperties,

    "& .sumbmitBtn": {
        height: "65px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px 0px 0px 0px",
        background: "#044352",
        width: "100%"
    },
    "& .sumbmitText": {
        fontFamily: "Urbanist",
        fontFize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
        color: "#FFFFFF"

    }
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .companyTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
})
// Customizable Area End