Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.welcomeTextSignIn ="Welcome back! Enter your details to continue with us.";
exports.forgotPasswordText ="Forgot password? Don't worry we have the way to reset it";
exports.chekcmail ="Check your email";
exports.sentMail ="We have sent a password reset link to";
exports.loginUserUrl ="/bx_block_login/logins";
exports.forgotPasswordUrl = "/account_block/accounts/forgot_password";
exports.noEmailBlank ="Email can't be blank";
exports.emailMessage ="Email should be valid ";
exports.passMessage = "Password can't be blank";
// Customizable Area End