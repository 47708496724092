import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { getStorageData } from "framework/src/Utilities";
interface UpdateUserProfile {
  error?: string;
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      website: string;
      linkedin: string;
      twitter: string;
      country: string;
      account_id: number;
      photo: string;
      full_name: string;
      date_of_birth: string;
      email: string;
    }
  },
  meta?: {
    message: string;
  }
}

interface UserProfile {
  error?: string;
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      website: string;
      linkedin: string;
      twitter: string;
      country: string;
      account_id: number;
      photo: string;
      full_name: string;
      date_of_birth: string;
      email: string;
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileId: string;
  errorMsg: string;
  selectedLanguage: string;
  name: string | undefined;
  emailAddress: string | undefined;
  birthDate: string | undefined;
  companyName: string | undefined;
  website: string | undefined;
  linkedin: string | undefined;
  twitter: string | undefined;
  uploadImgDropdown: boolean;
  uploadedImagePreview: string | ArrayBuffer | null;
  referenceImage: string | File | null | undefined;
  referenceImageError: string;
  errorMessageReferenceImage: string;
  saveProfileClicked: boolean;
  emailError: string;
  updateUserProfileResponse: UpdateUserProfile;
  userProfileData: UserProfile;
  openSuccess: boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  dateInputRef: null | React.RefObject<HTMLInputElement> = null;
  updateUserProfileApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      token: "",
      profileId: "",
      errorMsg: "",
      selectedLanguage: "",
      name: "",
      emailAddress: "",
      birthDate: "",
      companyName: "",
      website: "",
      linkedin: "",
      twitter: "",
      uploadImgDropdown: false,
      uploadedImagePreview: null,
      referenceImage: null,
      referenceImageError: "",
      errorMessageReferenceImage: "",
      saveProfileClicked: false,
      emailError: "",
      updateUserProfileResponse: {},
      userProfileData: {},
      openSuccess: false
    };

    this.dateInputRef = React.createRef<HTMLInputElement>();

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.updateUserProfileApiCallId) {
        this.handleUpdateProfileApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getUserProfileApiCallId) {
        this.handleUserProfileApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language });

    const signInResponse = await getStorageData("signInResponse");
    const parsedSignInResponse = JSON.parse(signInResponse)
    this.setState({
      token: parsedSignInResponse.meta?.token,
      profileId: parsedSignInResponse.meta?.profile_id
    });

    this.getUserProfileApiCall();
  }

  handleUserProfileApiResponse = (responseJson: UserProfile) => {
    if (responseJson && !responseJson.error) {
      this.setState({ userProfileData: responseJson }, () => 
        {
          this.setState({
            name: this.state.userProfileData.data?.attributes.full_name,
            emailAddress : this.state.userProfileData.data?.attributes.email,
            birthDate: this.state.userProfileData.data?.attributes.date_of_birth,
            companyName: this.state.userProfileData.data?.attributes.company_name,
            website: this.state.userProfileData.data?.attributes.website,
            linkedin: this.state.userProfileData.data?.attributes.linkedin,
            twitter: this.state.userProfileData.data?.attributes.twitter,
            referenceImage: this.state.userProfileData.data?.attributes.photo
          })
        })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleUpdateProfileApiResponse = (responseJson: UpdateUserProfile) => {
    if (responseJson && !responseJson.error) {
      this.setState({ updateUserProfileResponse: responseJson, openSuccess: true })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }
  handleCloseSuccess = () => {
    this.setState({openSuccess: false})
  }

  handleBtnDone = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasic");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  nameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ name: event.target.value })
  }

  emailAddressChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ emailAddress: event.target.value })
  }

  birthDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ birthDate: event.target.value })
  }

  companyNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ companyName: event.target.value })
  }

  websiteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ website: event.target.value })
  }

  linkedinChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ linkedin: event.target.value })
  }

  twitterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ twitter: event.target.value })
  }

  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const acceptedFormats = ['image/jpeg', 'image/png'];
    const maxFileSize = 5 * 1024 * 1024;

    if (file) {
      if (acceptedFormats.includes(file.type) && file.size < maxFileSize) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          this.setState({
            uploadImgDropdown: false,
            referenceImage: fileReader.result as string,
            referenceImageError: '',
          });
        };

        fileReader.readAsDataURL(file);
      } else {
        if (!acceptedFormats.includes(file.type)) {
          this.setState({
            uploadImgDropdown: false,
            referenceImageError: 'Please upload png and jpeg/jpg file only.',
          });
        }
        if (file.size > maxFileSize) {
          this.setState({
            uploadImgDropdown: false,
            referenceImageError: 'Please upload file with 5mb only.',
          });
        }
      }
    }
  };

  removeProfilePhoto = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({
      referenceImage: null,
      referenceImageError: '',
      uploadImgDropdown: false,
    });
  }

  uploadImageDropdown = () => {
    this.setState({
      uploadImgDropdown: true,
    });
  };

  validationEmail = () => {
    const errorInUserEmail = !this.state.emailAddress;
    let emailError = "";
    const emailRegex = configJSON.emailRegEx;

    if (errorInUserEmail) {
      emailError = configJSON.errorText
    } else if (!emailRegex.test(this.state.emailAddress)) {
      emailError = configJSON.validEmailErrorMsg
    }
    this.setState({ emailError })
  }

  saveProfile = () => {
    this.setState({ saveProfileClicked: true })
    const errorInName = !this.state.name;
    const errorInEmail = !this.state.emailAddress;
    const errorInBirthDate = !this.state.birthDate;
    const errorInCompanyName = !this.state.companyName

    let emailError1 = "";
    const emailRegex = configJSON.emailRegEx;

    if (errorInEmail) {
      emailError1 = configJSON.errorText;
    } else if (!emailRegex.test(this.state.emailAddress)) {
      emailError1 = configJSON.validEmailErrorMsg;
    }
    this.setState({ emailError: emailError1 })

    if (errorInName || errorInEmail || emailError1 || errorInBirthDate || errorInCompanyName) {
      return;
    }
    this.setState({ emailError: "" })

    this.updateUserProfileApiCall();
  }

  getUserProfileApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showUserProfileApiEndPoint}/${this.state.profileId}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  updateUserProfileApiCall = () => {
    const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    const body = {
      "profile": {
        "full_name": this.state.name,
        "date_of_birth": this.state.birthDate,
        "company_name": this.state.companyName,
        "website": this.state.website,
        "linkedin": this.state.linkedin,
        "twitter": this.state.twitter,
        "photo": this.state.referenceImage
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserProfileApiEndpoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End

}
