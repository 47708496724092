Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatgptintegration9";
exports.labelBodyText = "cfchatgptintegration9 Body";

exports.btnExampleTitle = "CLICK ME";

exports.POST="POST"
exports.createJobDescApiEndPoint= "bx_block_dashboardguests/job_descriptions"
exports.getJobDescLevelApiEndPoint= "bx_block_dashboardguests/job_descriptions/job_level_list"
exports.getJobDescExpApiEndPoint= `bx_block_dashboardguests/job_descriptions/job_experience_list`
// Customizable Area End