// Customizable Area Start
import React from "react";
import { Typography, Checkbox, Box, Button, TextField, Dialog, DialogTitle, DialogContent, IconButton, Divider, InputAdornment, Grid, styled, InputLabel } from "@mui/material";
import WelcomeScreenController, { Props, configJSON } from "./WelcomeScreenController.web";
import CloseIcon from "@mui/icons-material/Close";
import { mainLogo, signupImage, underReviewImage } from "./assets";

import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  ThemeProvider, createTheme

} from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
})


const BpIcon = styled('span')(({ theme }) => ({

  borderRadius: 6,
  border: '1px solid #64748B',
  width: 20,
  height: 20,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      'rgba(206,217,224,.5)',
  },
  [theme.breakpoints.up('xs')]: {
    width: 12,
    height: 12,
  },
  [theme.breakpoints.up('sm')]: {
    width: 16,
    height: 16,
  },
  [theme.breakpoints.up('md')]: {
    width: 18,
    height: 18,
  },
  [theme.breakpoints.up('lg')]: {
    width: 20,
    height: 20,
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});



const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: "Urbanist",
  fontSize: "24px",
  color: "#000000"
});

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  fontFamily: "Urbanist",
  fontSize: "24px",
  color: "#000000",
  lineHeight: "32px"
});

// Customizable Area End

// Customizable Area Start

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '1rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },
  button: {
    width: '120px',
    borderRadius: '8px',
    padding: '1rem',
  },
  buttonCancel: {
    background: '#F1F5F9',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  buttonCancelText: {
    textTransform: 'none',
    color: '#044352',
  },
  buttonAgree: {
    background: '#044352',
    '&:hover': {
      background: 'none',
    },
  },
  buttonAgreeText: {
    textTransform: 'none',
    color: '#ffffff',
  },
};

const webStyle = {
  positionAIstyle: {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px',
    width: '100%',
  },
  signupStylebtn: {
    fontFamily: 'Urbanist, sans-serif',
    textTransform: "none",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: "18px"
  },
  OtpBox: {
    width: "50px", height: "44px", borderRadius: "8px", textAlign: "center",
  },
  otpInput: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
    '& input': {
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.5rem',
    },
  },
  GetstartedStyle: {
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    margin: '12px 0',
    color: '#475569',
    width: "104%"

  },
  sigupwithgoogle: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: "none",
    color: "#000000"

  },

  borderStyle: {
    width: '100%',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
    }
  },
  agreeStyle: {

    fontFamily: 'Poppins, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  commonLable: {
    marginTop: "1rem",
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: "#334155"

  },
  AlreadyStyle: {
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#0F172A',
    letterSpacing: '0.12px',

  },
  logStyles: {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    marginBottom: '12px',
    color: "#044352",
    cursor: "pointer"

  },
  LinkStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    marginTop: "1rem"
  },
  flex_pro: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.5rem",
    marginTop: "1rem",
    marginLeft: "-10px"
  },
  hussleStyle: {
    margin: 0,
    textAlign: 'center' as 'center',
    width: "100%",
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '44px',
    letterSpacing: '-0.5%',
    color: '#ffffff',
  },
  hussleStyles: {
    margin: 0,
    textAlign: 'center' as 'center',
    width: "100%",
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '-0.5%',
    color: '#ffffff',
  },
  orStyledash: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '2rem',
    marginBottom: "2rem"
  },
  Signpwithgoglestyle: {
    width: '503.71px',
    padding: "0.8rem",


  },
  spanStyle: {

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.6rem"
  },
  inputLabelStyle: {
    marginBottom: '10px',
    height: '22px',
    color: "#757575",
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  EmailOutlinedIcon: {
    color: "#b5b5be"
  },
  signupstyle: {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    letterSpacing: '-0.5%',
    color: '#000000',
  },
  privacyText: {
    fontSize: "16px",
    color: "#1C6BDF",
    cursor: "pointer"
  },
  signupBtn: {
    color: "#044352"
  },
  trytologinBtn: {
    color: "#044352"
  },
  flex_Check: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    gap: "0.6rem",
    marginLeft: "-10px"
  },
  privacyPlici: {
    fontWeight: 400,
    fontFamily: "Urbanist",
    fontSize: "18px",
    color: "#0F172A",
    lineHeight: "16px",

  },

  erroMessage: {
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: "#DC2626",
  },
  textFeild: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px', '& fieldset': {
        borderColor: '#CBD5E1',
      },
    },
    '& .MuiInputBase-input': {
      color: '#0F172A',
      fontFamily: 'Urbanist',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.08px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      fontFamily: 'Urbanist',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.08px',
    },
    '& .MuiInputBase-input:hover': {
      borderColor: '#1976d2',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginTop: '4px',
    }
  }





};


export const Wrapper: any = styled(Box)({
  "& .signInText": {
    borderRadius: "8px",
    maxWidth: "245px",
    width: "120px",
    padding: "1rem",
    backgroundColor: "#044352",
    "&:hover": {
      backgroundColor: "#044352",
    },
  },
})
// Customizable Area End

export class WelcomeScreen extends WelcomeScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTermsDialog = () => {
    return (
      <Dialog
        open={this.state.termsOpen}
        onClose={this.handleTermsClose}
        PaperProps={{ sx: { overflow: 'hidden', borderRadius: "8px 8px 32px 8px" } }}
      >
        <StyledDialogTitle>
          {this.state.termsAndConditionData?.title}
          <IconButton onClick={this.handleTermsClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <Divider />
        <StyledDialogContent sx={{ overflowX: 'auto', }}>
          <Typography sx={webStyle.privacyPlici}>
            {this.state.termsAndConditionData?.description}
          </Typography>
          <Box sx={webStyle.flex_Check}>
            <Box>
              <Checkbox
                name="isTermsChecked"
                sx={{
                  '& .MuiSvgIcon-root': {
                    '& .MuiSvgIcon-root': {
                      borderRadius: '6px',
                      border: '1px #64748B',
                    },
                  },
                }} data-test-id="checkbox"
                checked={this.state.termsDetails.isTermsChecked}
                onChange={this.handleCheckboxChange}

              />
            </Box>
            <Box>
              <Typography sx={webStyle.privacyPlici} >
                {this.state.termsAndConditionData?.checkbox}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px' }}>
            <Typography
              style={webStyle.erroMessage}
            >
              {!this.state.termsDetails.isTermsChecked && this.state.termsMessage}

            </Typography>
          </Box>
        </StyledDialogContent>
        <Divider />
        <Wrapper>
          <Box sx={styles.container}>
            <Box sx={styles.buttonGroup}>
              <Button onClick={this.handleTermsClose} sx={{ ...styles.button, ...styles.buttonCancel }}>
                <span style={{ fontSize: "16px", fontWeight: 700, fontFamily: "Urbanist", textTransform: 'none', color: "#044352" }}>Cancel</span>
              </Button>
              <Button onClick={this.checkForTerms} className="signInText">
                <span style={{ fontFamily: "Urbanist", textTransform: 'none', color: "#ffffff", fontSize: "16px", fontWeight: 700 }}>Agree</span>
              </Button>
            </Box>
          </Box>
        </Wrapper>
      </Dialog>

    );
  }
  getHelperText = (fieldName:string) => {
    const { apiErrors, existingEmail } = this.state;
  
    if (fieldName === 'full_name') {
      return apiErrors?.full_name ? apiErrors?.full_name.join(", ") : "";
    }else if (fieldName === 'email') {
      if (apiErrors?.email) {
        return apiErrors?.email.join(", ");
      } else if (existingEmail) {
        return existingEmail.join(", ");
      } else {
        return "";
      }
    } else if (fieldName === 'password') {
      return apiErrors?.password ? apiErrors?.password.join(", ") : "";
    } else {
      return "";
    }
  };
  

  moreItems = () => {
    return (
      <>
        {this.state.userSignedUp ? <><Box>
          <img style={{ width: "346px", height: "328px" }} src={underReviewImage.default} />
        </Box>
          <Box>
            <Grid container>
              <Grid item md={7} sm={12}>
                <Box sx={{
                  padding: "0.5rem", marginTop: '1.3rem',
                  backgroundColor: "#044352",
                }}>
                  <Button fullWidth style={webStyle.signupBtn} onClick={() => this.goTosignin()}
                    data-test-id="trytologin">
                    <Typography style={{ textTransform: "capitalize", color: "white" }}>Try to login</Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box></> :
          <div  >
            <Box
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', marginBottom: "0.25rem" }
              }}
              noValidate
              autoComplete="off"
            >

              <InputLabel sx={webStyle.commonLable} htmlFor="my-input">
                Full Name
              </InputLabel>
              <TextField
                required
                data-test-id="fullname"
                name="full_name"
                placeholder="Your full name"

                sx={webStyle.textFeild}
                value={this.state.userDetails.full_name}
                onChange={this.handleChange}
                error={!!this.state.apiErrors?.full_name}
                helperText={this.getHelperText('full_name')}

              />
            </Box>

            <Box
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', marginTop: "0.25rem" },
              }}
              noValidate
              autoComplete="off"
            >

              <InputLabel sx={webStyle.commonLable} htmlFor="my-input">
                Email
              </InputLabel>
              <TextField
                required
                data-test-id="Email"
                name="email"
                value={this.state.userDetails.email}
                onChange={this.handleChange}
                placeholder="Your email"
                sx={webStyle.textFeild}
                error={!!this.state.apiErrors?.email || !!this.state.existingEmail}
                helperText={this.getHelperText('email')}

              />
            </Box>

            <Box component="form" sx={{
              '& > :not(style)': { width: '100%', marginTop: "0.25rem" },
            }}
              noValidate
              autoComplete="off"
            >

              <InputLabel sx={webStyle.commonLable} htmlFor="my-input">
                Password
              </InputLabel>
              <TextField data-test-id="password"
                required

                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.userDetails.password}
                onChange={this.handleChange}
                error={!!this.state.apiErrors?.password}
                helperText={this.getHelperText('password')}
                placeholder="Your password"
                style={{ width: "100%" }}
                sx={webStyle.textFeild}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>



            <Box sx={webStyle.flex_pro}>
              <Box>
                <Checkbox
                  sx={{
                    '& .MuiSvgIcon-root': {
                      '& .MuiSvgIcon-root': {
                        borderRadius: '6px',
                        border: '1px #64748B',
                      },
                    },
                  }}
                  name="ischeckboxChecked"
                  data-test-id="checkbox"
                  checked={this.state.userDetails.ischeckboxChecked}
                  onChange={this.handleCheckboxChange}
                  checkedIcon={<BpCheckedIcon />}


                />
              </Box>


              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Urbanist',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: "#0F172A",
                  }}
                >
                  I agree with
                  <span onClick={this.handlePolicyOpen} style={{ ...webStyle.privacyText, color: '#1C6BDF' }}> Privacy Policy </span>
                  and
                  <span onClick={this.handleTermsOpen} style={{ ...webStyle.privacyText, color: '#1C6BDF' }}> Terms and Conditions </span>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: '8px' }}>
              <Typography
                style={webStyle.erroMessage}
              >
                {!this.state.userDetails.ischeckboxChecked && this.state.apiErrors?.term_condition}
              </Typography>
            </Box>



            <Box>
              <Grid container>
                <Grid item md={9} lg={9} sm={12} xs={12}>
                  <Box sx={{
                    padding: "0.5rem", marginTop: '1.3rem', backgroundColor: '#044352'

                  }}>
                    <Button onClick={this.SignUp} fullWidth style={webStyle.signupBtn}

                      data-test-id="signuubutton">
                      <span style={{
                        fontFamily: 'Urbanist',
                        textTransform: "none",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: "18px"
                      }}>Sign up</span>
                    </Button>
                  </Box>
                  <Box sx={webStyle.LinkStyle}>
                    <Box>
                      <span style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
                        <Typography sx={{ ...webStyle.AlreadyStyle, margin: 0 }}>Already have an account?</Typography>
                        <Typography onClick={() => this.goTosignin()} sx={{ ...webStyle.logStyles, margin: 0 }}>Login</Typography>
                      </span>
                    </Box>
                    <Box >

                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>


          </div>}
      </>
    )
  }

  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Grid container sx={{ width: "99% !important" }} >

            <Grid item md={6} xs={12} sm={12}>
              <Box sx={{ color: 'black' }} >
                <Box style={{ marginTop: '20px' }}>

                  <Grid container justifyContent="center"
                    alignItems="center" >
                    <Grid item md={9} sm={11} xs={11}

                    >

                      <Box style={{ padding: '1rem' }}>
                        <img onClick={this.handleNavigateLanding} style={{ width: "110px", height: "110px", transform: 'translateX(-10px)' }} src={mainLogo.default} alt="Weddng__W" />
                      </Box>
                      <Box sx={{ marginBottom: "22px" }}>
                        {this.state.userSignedUp ? <Typography sx={webStyle.signupstyle}>{configJSON.Welcometext} <br /> is currently under review. ⏳</Typography> : <Typography style={webStyle.signupstyle}>Welcome to the AI-Powered <br /> Hiring Process</Typography>}
                        {this.state.userSignedUp ? <Typography style={webStyle.GetstartedStyle}>{configJSON.reviewUser}</Typography> : <Typography style={webStyle.GetstartedStyle}>{configJSON.shortMessage}</Typography>}

                      </Box>
                      <this.moreItems />



                    </Grid>



                  </Grid>
                </Box>
              </Box>
            </Grid>



            <Grid item md={6} xs={12} sm={12}>
              <Box
                sx={{
                  position: 'relative',
                  color: 'white',
                  textAlign: 'center',

                }}
              >
                <img src={signupImage.default} alt="HR_Tech" style={{
                  maxWidth: '100%', filter: 'brightness(100%)', borderRadius: "0px 0px 0px 64px"
                }} />
                <Box
                  sx={webStyle.positionAIstyle}
                >

                  <p style={webStyle.hussleStyle}>
                    Revolutionize Your Hiring Process with <br /> AI-Powered Talent Management
                  </p>

                  <Box style={{ width: "100%", marginTop: "33px" }}>
                    <p style={webStyle.hussleStyles}>  Fusce volutpat lectus et nisl consectetur finibus In vitae <br /> scelerisque augue, in varius eros.</p>
                  </Box>




                </Box>

              </Box>
            </Grid>
          </Grid>



        </ThemeProvider>

        <Dialog open={this.state.policyOpen} onClose={this.handlePolicyClose}
          PaperProps={{ sx: { overflow: 'hidden', borderRadius: "8px 8px 32px 8px" } }}>
          <StyledDialogTitle>
            {this.state.privacyPolicyData.title}
            <IconButton onClick={this.handlePolicyClose}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <Divider />
          <StyledDialogContent sx={{ overflowX: 'auto', }}>
            <Typography sx={webStyle.privacyPlici}>
              {this.state.privacyPolicyData.description}
            </Typography>
            <Box sx={webStyle.flex_Check}>
              <Box>
                <Checkbox
                  name="isPrivacyPolicyChecked"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      borderRadius: '6px',
                      border: '1px #64748B',
                    },
                  }}
                  data-test-id="checkbox"
                  checked={this.state.policyDetails.isPrivacyPolicyChecked}
                  onChange={this.handleCheckboxChange}
                  checkedIcon={<BpCheckedIcon />}

                />
              </Box>
              <Box sx={{ marginLeft: "-10px" }}>
                <Typography sx={webStyle.privacyPlici}>{this.state.privacyPolicyData.checkbox}</Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: '8px' }}>
              <Typography
                style={webStyle.erroMessage}
              >
                {!this.state.policyDetails.isPrivacyPolicyChecked && this.state.policyMessage}

              </Typography>
            </Box>

          </StyledDialogContent>
          <Divider />
          <Wrapper>
            <Box sx={{ display: 'flex', justifyContent: "right", alignItems: "right", padding: "1rem" }}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "1rem" }}>
                <Button onClick={this.handlePolicyClose} sx={{ width: "120px", borderRadius: "8px", background: '#F1F5F9', boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", padding: '1rem' }}>
                  <span style={{ fontSize: "16px", fontWeight: 700, fontFamily: "Urbanist", textTransform: 'none', color: "#044352" }}>Cancel</span>
                </Button>

                <Button onClick={this.checkifPolicy} className="signInText">
                  <span style={{ fontFamily: "Urbanist", textTransform: 'none', color: "#ffffff", fontSize: "16px", fontWeight: 700 }}>Agree</span>
                </Button>
              </Box>
            </Box>
          </Wrapper>
        </Dialog>


        {this.renderTermsDialog()}
      </>
    )
  }



  // Customizable Area End
}

// Customizable Area Start


export default WelcomeScreen;
// Customizable Area End
